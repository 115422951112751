import { graphql } from "gql";
import request from "graphql-request";
import { BACKEND_DOMAIN } from "utils/constants";
import { buildReportsRequestVariables, buildRequestHeaders } from "utils/helpers";

const createReportFiltersetFn = (
    accessToken: string, 
    filters: Filters
) => 
    request({
        url: `${BACKEND_DOMAIN}/graphql`,
        document: mutationQuery,
        variables: buildReportsRequestVariables(filters),
        requestHeaders: buildRequestHeaders(accessToken),
    }).then((data) => {
        if (data) {
            return data;  
        }
        throw new Error("Error creating report filterset");
    })
    .catch((error) => {
        console.error("GraphQL Error:", error);
        throw error; 
    });

const mutationQuery = graphql(`
    mutation createReportFilterset($reportFilter: ReportFilter!) {
        createReportFilterset(reportFilter: $reportFilter) 
    }
`);

export default createReportFiltersetFn;