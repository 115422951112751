import { FutureProjectionEnergyConsumptionCard } from "components/organisms/Charts";
import { useCumulativeEnergyConsumptionCard } from "queries/Reports/Baseline/useCumulativeEnergyConsumptionCard";

function CumulativeEnergyConsumptionCard() {
    const { data, isLoading, error } = useCumulativeEnergyConsumptionCard();
    return (
        <FutureProjectionEnergyConsumptionCard
            data={data}
            cumulative={true}
            loading={isLoading}
            error={error?.toString()}
        />
    );
}

export default CumulativeEnergyConsumptionCard;