import { Modal } from "antd";
import { Button } from "components/atoms/Button";
import { on } from "events";
import CashFlowChartMeasuresReport from "./CashFlowChartMeasuresReport";

interface MeasuresReportModalProps {
    modalIsOpen: boolean;
    rowData: MeasuresMeasuresReportTableRow | null;
    // email: string;
    // handleMenuItemClick: (action: string) => void;
    onCancel: () => void;
}

function MeasuresReportModal({
    modalIsOpen,
    onCancel,
    rowData
    // handleMenuItemClick,
}: MeasuresReportModalProps) {
    return (
        <Modal
            width="100%"
            open={modalIsOpen}
            onCancel={onCancel}
            title="Annual Cash Flow"
            // className={styles.modalContainer}
            footer={
                <MeasuresReportFooter
                    onCancel={onCancel}
                />
            }

        >
            <div>
            {/* <div className={styles.firstHeader}> */}
                {rowData && <CashFlowChartMeasuresReport rowData={rowData} />}
            </div>
        </Modal>
    )
}

interface MeasuresReportFooterProps {
    onCancel: () => void;
}

function MeasuresReportFooter({
    onCancel,
}: MeasuresReportFooterProps) {
    return (
        <div className="bulk-edit-tags__footer" style={{ marginTop: "40px" }}>

            <Button
                type="link"
                onClick={onCancel}
                style={{ color: "var(--audette-black)" }}
            >
                Close
            </Button>
        </div>
    );
}

export default MeasuresReportModal;