import { useMemo, useState } from "react";
import { getDisplayInfo } from "utils/formatting";
import { UseQueryResult } from "react-query";
import { ChartErrorState } from "components/molecules/ErrorStates";
import ChartContainer from "components/molecules/ChartContainer";
import { BarChart } from "components/molecules/Charts";
import { line } from "d3-shape";
import { Table } from "components/molecules/Table";
import { ChartNoDataState } from "components/molecules/NoDataState";
import { KEY_TO_COLOR_MAP, LINE_KEY, MARGIN } from "./AnnualCashFlowChart.constants";

interface AnnualCashFlowChartProps<T> {
    query: (() => UseQueryResult<T[]>) | T[];
    tableColummnKeys: string[];
    barKeys: string[];
    height?: number;
    parentComponent?: string;
}

interface CashFlowData {
    cashFlowAnnual: number;
    incrementalCost: number;
    utilityCostSavingsElectricity: number;
    utilityCostSavingsNaturalGas: number;
    carbonTaxSavings: number;
    likeForLikeCost: number;
    cashFlowAnnualHidden: number;
    calendarYear: number;
}


function AnnualCashFlowChart<T extends { [key: keyof any]: number }>({
    query,
    tableColummnKeys,
    barKeys,
    height = 187,
    parentComponent
}: AnnualCashFlowChartProps<T>) {
    let data: T[] | undefined;
    let isLoading: boolean | undefined;
    let isError: boolean | undefined;
    
    if (parentComponent) {
        data = Array.isArray(query) ? query : undefined;
    } else {
        const queryResult = (query as () => UseQueryResult<T[]>)();
        ({ data, isLoading, isError } = queryResult);
    }
    const [showChart, setShowChart] = useState(true);

    const barData = useMemo(() => {
        if (!data || data?.length === 0) return [];

        return data.map((datum) => {
            const object: { [key: string]: number } = {};

            barKeys.forEach((key) => {
                const { humanReadable } = getDisplayInfo(key);

                const value =
                    key.toLowerCase().includes("cost") &&
                    !key.toLowerCase().includes("saving")
                        ? datum[key] * -1
                        : datum[key];
                object[humanReadable] = value;
            });
            return {
                ...object,
                "Net cash flow": datum.cashFlowCumulative,
                label: datum.year || datum.calendarYear,
            };
        });
    }, [data]);

    const chartLabels = useMemo(
        () => barKeys.map((key) => getDisplayInfo(key).humanReadable),
        [barKeys]
    );

    const maxValue = useMemo(() => {
        if (barData.length === 0) return 0;
        const allValues = barData.flatMap((datum) => {
            // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
            const { label, ...remainder } = datum;
            return Object.values(remainder);
        });
        return Math.max(...allValues);
    }, [barData]);

    const minValue = useMemo(() => {
        if (barData.length === 0) return 0;
        const allValues = barData.flatMap((datum) => Object.values(datum));
        return Math.min(...allValues);
    }, [barData]);

    const { formatFunction: formatCost, unit } = useMemo(
        () =>
            getDisplayInfo(
                "cashFlowCumulative",
                barData.flatMap((datum) => Object.values(datum))
            ),
        [barData]
    );

    const columns = useMemo(
        () =>
            tableColummnKeys.map((col) => {
                const values = data?.map((d) => d[col]);
                const { unit, formatFunction, humanReadable } =
                    data && data?.length > 0 && typeof data[0][col] === "number"
                        ? getDisplayInfo(col, values)
                        : getDisplayInfo(col);

                return {
                    render: formatFunction,
                    title: `${humanReadable} ${unit && `(${unit})`}`,
                    key: col,
                };
            }),
        [data]
    );

    const chartColorsMappedToLabels = useMemo(() => {
        const chartColorMap = new Map();
        barKeys.forEach((key) => {
            const { humanReadable } = getDisplayInfo(key);
            chartColorMap.set(humanReadable, KEY_TO_COLOR_MAP[key]);
        });

        return chartLabels
            .filter(
                (label) =>
                    label !== getDisplayInfo("calendarYear").humanReadable &&
                    label !== getDisplayInfo("year").humanReadable
            )
            .map((label) => ({
                color: chartColorMap.get(label),
                label,
            }));
    }, [chartLabels, barKeys]);

    const xLabelsToShow = useMemo(
        () =>
            barData.length > 1
                ? [barData[0].label, barData[barData.length - 1].label]
                : [],
        [barData]
    );

    if (isError) return <ChartErrorState />;
    if (data === null) return <ChartNoDataState />;

    return (
        <ChartContainer
            title="Annual cash flow"
            loading={isLoading}
            onClickTable={() => setShowChart(!showChart)}
        >
            {showChart ? (
                <BarChart
                    data={barData}
                    chartHeight={height}
                    includeLegend={true}
                    chartKeys={chartLabels}
                    margin={MARGIN}
                    chartColorsMappedToKeys={[
                        ...chartColorsMappedToLabels,
                        { label: "Net cash flow", color: "#EB03AD" },
                    ]}
                    valueScale={{
                        type: "linear",
                        max: maxValue,
                        min: minValue,
                    }}
                    customLayers={[
                        // eslint-disable-next-line react/no-unstable-nested-components
                        (props) => (
                            <LineLayer {...props} indexByKey={chartLabels[0]} />
                        ),
                    ]}
                    yAxisInfo={{
                        formatFunction: (v) => formatCost(v, false),
                        label: unit || "",
                    }}
                    xAxisInfo={{
                        xLabelsToShow,
                    }}
                />
            ) : (
                <div
                    style={{
                        maxHeight: "530px",
                        width: "100%",
                        overflow: "auto",
                    }}
                >
                    <Table
                        dataSource={
                            data?.map((datum) => ({
                                key: datum.year,
                                ...datum,
                            })) || []
                        }
                        pagination={false}
                        columns={columns}
                    />
                </div>
            )}
        </ChartContainer>
    );
}

function LineLayer({ bars, xScale, yScale, indexByKey }: any) {
    const filteredBars = bars.filter((bar: any) =>
        bar.key.includes(indexByKey)
    );

    const lineGenerator = line()
        .x((bar: any) => xScale(bar.data.indexValue) + bar.width / 2)
        .y((bar: any) => yScale(bar.data.data[LINE_KEY] || 0));

    return (
        <path
            d={lineGenerator(filteredBars) || undefined}
            fill="none"
            stroke="#EB03AD"
            style={{ pointerEvents: "none" }}
            strokeDasharray="10,10"
            strokeWidth={2}
        />
    );
}



export default AnnualCashFlowChart;
