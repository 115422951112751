import { EndUseStackedBarChart } from "components/organisms/Charts";
import { useAnnualCarbonEmissionsNaturalGasChart } from "queries/Reports/Baseline/useAnnualCarbonEmissionsNaturalGasChart";

function AnnualCarbonEmissionsNaturalGasChart() {
    const { data, isLoading, error } =
        useAnnualCarbonEmissionsNaturalGasChart();

    return (
        <EndUseStackedBarChart
            data={data}
            loading={isLoading}
            fuelTypeKey="naturalGas"
            error={error?.toString()}
        />
    );
}

export default AnnualCarbonEmissionsNaturalGasChart;