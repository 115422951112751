import { FutureProjectionEnergyConsumptionCard } from "components/organisms/Charts";
import { useAnnualEnergyConsumptionCard } from "queries/Reports/Baseline/useAnnualEnergyConsumptionCard";
import { useMemo } from "react";

function AnnualEnergyConsumptionCard({
    selectedYear,
}: {
    selectedYear: number;
}) {
    const { data, isLoading, error } = useAnnualEnergyConsumptionCard();

    const dataForYear = useMemo(() => {
        if (!data) return undefined;
        return data.find((datum) => datum?.year === selectedYear);
    }, [selectedYear, data]);

    return (
        <FutureProjectionEnergyConsumptionCard
            data={dataForYear}
            cumulative={true}
            loading={isLoading}
            error={error?.toString()}
        />
    );
}

export default AnnualEnergyConsumptionCard;