    import { useEffect, useMemo } from "react";

    import { Table } from "components/molecules/Table";
    import { getDisplayInfo } from "utils/formatting";

    import "./MeasuresProjectsReport.scss";
    import { ComponentErrorBoundary } from "components/molecules/ErrorStates";
    import errorFallbackUI from "components/molecules/ErrorFallbackUI.tsx/ErrorFallbackUI";
    import { useFilteredBuildings } from "hooks/Building/useFilteredBuildings";
import { useNavigate } from "react-router-dom";

    interface MeasuresProjectsReportProps {
        filtersetId: string | null;
        setExportData: (data: any) => void;
        setExportProjectsColumnsNames: (data: any) => void;
    }

    function MeasuresProjectsReport({ 
        filtersetId, 
        setExportData, 
        setExportProjectsColumnsNames 
    }: MeasuresProjectsReportProps) {
        const { data: filteredBuildings } = useFilteredBuildings(filtersetId);
        const navigate = useNavigate();

        // Not using useMemo as this is not performance intensive, likely less than using useMemo
        const buildingsArray = filteredBuildings?.filteredBuildings;

        const formattedData = Array.isArray(buildingsArray)
            ? buildingsArray.flatMap((building) => {
                if (
                    !building.recommendedCrp ||
                    !building.recommendedCrp.carbonReductionMeasures
                ) {
                    return [];
                }

                const measures =
                    building.recommendedCrp.carbonReductionMeasures.map(
                        (measure) => ({
                            carbonReductionMeasureType: measure?.carbonReductionMeasureType ?? '',
                            carbonReductionMeasureCategory: measure?.carbonReductionMeasureCategory ?? '',
                            buildingName: building.buildingName ?? '',
                            streetAddress: `${building.streetAddress ?? ''}, ${building.stateProvince ?? ''}, ${building.postalZipCode ?? ''}`,
                            yearApplied: measure?.yearApplied ?? 0,
                            annualEnergyConsumptionSavings:
                                measure?.energyConsumptionSavingsTotal ?? 0,
                            annualCarbonEmissionSavings:
                            measure?.annualMeanCarbonEmissionSavings ?? 0,
                            likeForLikeCost: measure?.likeForLikeCost ?? 0,
                            incrementalCost: measure?.incrementalCost ?? 0,
                            totalMeasureCost: measure?.measureCost ?? 0,
                            totalMeasureCostIntensity: ((measure?.measureCost ?? 0) / (building.grossFloorArea ?? 1)),
                            annualMeanUtilityCostSavings:
                            measure?.annualMeanUtilityCostSavings ?? 0,
                            annualCarbonTaxSavings: measure?.lifetimeCarbonTaxSavingsMean ?? 0,
                            netPresentValue: measure?.netPresentValueTotal ?? 0,
                            internalRateOfReturn: measure?.internalRateOfReturnTotal ?? 0,
                            returnOnInvestment: measure?.returnOnInvestmentTotal ?? 0,
                            simplePayback: measure?.simplePaybackYearsTotal ?? 0,
                            marginalAbatementCost: measure?.marginalAbatementCostTotal ?? 0,
                            buildingModelUid: building.buildingModelUid ?? '',
                        })
                    );

                return measures
            }, [])
            : [];


        const tableColumns = useMemo(() => {

            if (!formattedData || formattedData.length === 0) return null;
            const formattedDataKeys = Object.keys(formattedData[0])

            return formattedDataKeys.map((key) => {
                const typedKey = key as keyof MeasuresProjectsReportTableRow;
                if (typeof formattedData[0][typedKey] === "string") {
                    const { formatFunction, humanReadable, tooltip } =
                        getDisplayInfo(key);

                    return {
                        render: formatFunction,
                        key,
                        title: humanReadable,
                        tooltip,
                    };
                }

                const columnValues = formattedData.map((row) => row[typedKey]) as number[];

                const { formatFunction, humanReadable, unit, tooltip } =
                    getDisplayInfo(key, columnValues);

                return {
                    render: formatFunction,
                    key,
                    title: `${humanReadable} ${unit && `(${unit})`}`,
                    tooltip,
                };
            });
        }, [formattedData]);

        const exportColumnsNames = tableColumns?.map((column) => column.title) ?? [];

        const dataSource = useMemo(() => {
            if (!formattedData) return null;

            
            const filteredData = formattedData.filter((row) => (row.carbonReductionMeasureType !== "No Action" && row.carbonReductionMeasureType !== "Base building state"))
            
            return filteredData.map((row, index) => ({
                ...row,
                key: `${row.carbonReductionMeasureType ? row.carbonReductionMeasureType : 'defaultKey'}-${index}`,
            }));
        }, [formattedData]) ;

        useEffect(() => {
            // Using preventive state update to avoid a rerender that bugs pagination
            if (dataSource) {
                setExportData((prev: any) => {
                    if (JSON.stringify(prev) !== JSON.stringify(dataSource)) {
                        return dataSource;
                    }
                    return prev;
                });
            }
        
            if (exportColumnsNames) {
                setExportProjectsColumnsNames((prev: any) => {
                    if (JSON.stringify(prev) !== JSON.stringify(exportColumnsNames)) {
                        return exportColumnsNames;
                    }
                    return prev;
                });
            }
        }, [dataSource, exportColumnsNames]);

        if (!dataSource || !tableColumns) return null;

        const handleProjectRowClick = (row: MeasuresProjectsReportTableRow) => {
            const { buildingModelUid: uid, carbonReductionMeasureType, carbonReductionMeasureCategory } = row;

            if (uid) navigate(`/building/${uid}?tab=projects&carbonReductionMeasureType=${carbonReductionMeasureType}&carbonReductionMeasureCategory=${carbonReductionMeasureCategory}`);
        }

        return (
            <div className="measures-projects-report">
                <ComponentErrorBoundary
                    fallback={errorFallbackUI("tableError")}
                    originComponent="MeasuresProjectsReport"
                >
                    {/* <CsvDownloadButton data={dataSource} filename="Projects" headers={exportColumnsNames} /> */}
                    <Table
                        dataSource={dataSource}
                        columns={tableColumns}
                        className="projects-report-table"
                        pagination={true}
                        onRowClick={(row) => handleProjectRowClick(row)}
                    />
                </ComponentErrorBoundary>
            </div>
        );
    }

    export default MeasuresProjectsReport;
