import errorFallbackUI from "components/molecules/ErrorFallbackUI.tsx/ErrorFallbackUI";
import { ComponentErrorBoundary } from "components/molecules/ErrorStates";
import { Table } from "components/molecules/Table";
import { useFilteredBuildings } from "hooks/Building/useFilteredBuildings";
import { useEffect, useMemo, useState } from "react";
import { getDisplayInfo } from "utils/formatting";
import "./MeasuresMeasuresReport.scss";
import MeasuresReportModal from "./Modal/MeasuresReportModal";

interface MeasuresMeasuresReportProps {
    filtersetId: string | null;
    setExportData: (data: any) => void;
    setExportColumnsNames: (data: any) => void;
}

interface CashFlowYear {
    calendarYear: number;
    carbonTaxSavings: number;
    cashFlowAnnualHidden: number;
    cashFlowCumulative: number;
    incrementalCost: number;
    utilityCostSavingsElectricity: number;
    utilityCostSavingsNaturalGas: number;
}


function MeasuresMeasuresReport({ 
    filtersetId, 
    setExportData, 
    setExportColumnsNames 
}: MeasuresMeasuresReportProps) {
    const { data: filteredBuildings } = useFilteredBuildings(filtersetId);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState<MeasuresMeasuresReportTableRow | null>(null);
    const buildingsArray = filteredBuildings?.filteredBuildings;

    const handleCancel = () => {
        setModalIsOpen(false);
    }    
    
    const formattedData = Array.isArray(buildingsArray)
    ? buildingsArray.flatMap((building) => {
          if (
              !building.recommendedCrp ||
              !building.recommendedCrp.carbonReductionMeasures ||
              !building.grossFloorArea
          ) {
              return [];
          }
          const measures =
              building.recommendedCrp.carbonReductionMeasures.map(
                // eslint-disable-next-line arrow-body-style
                  (measure: any) => {
                    // const cashFlowAnnualHidden: CashFlowYear[] = [];
                    // const measureLife = measure.measureLife || 1;
                    
                    // let cumulativeCashFlow = 0;
                    
                    // for (let i = 0; i < measureLife; i++) {
                    //     const isFirstYear = i === 0;
                        
                    //     // IMPORTANT - Cash Flow formula is NOT functional until data is standardized (years with no measures filled for empty years) 
                    //     const yearCashFlow: CashFlowYear = {
                    //         calendarYear: measure.yearApplied + i, 
                    //         carbonTaxSavings: measure.lifetimeCarbonTaxSavings || 0,
                    //         utilityCostSavingsElectricity: measure.energyConsumptionSavingsElectricity || 0,
                    //         utilityCostSavingsNaturalGas: measure.energyConsumptionSavingsNaturalGas || 0,
                    //         incrementalCost: isFirstYear ? (measure.incrementalCost || 0) : 0,
                    //         cashFlowAnnualHidden: isFirstYear 
                    //             ? -(measure.incrementalCost || 0) + (measure.lifetimeUtilityCostSavings?.[i] || 0)
                    //             : (measure.lifetimeUtilityCostSavings?.[i] || 0),
                    //         cashFlowCumulative: 0
                    //     };
                        
                    //     cumulativeCashFlow += yearCashFlow.cashFlowAnnualHidden;
                    //     yearCashFlow.cashFlowCumulative = cumulativeCashFlow;
                        
                    //     cashFlowAnnualHidden.push(yearCashFlow);
                    // }
                    
                    return{
                      carbonReductionMeasureType: measure?.carbonReductionMeasureType,
                      carbonReductionMeasureCategory: measure?.carbonReductionMeasureCategory ?? '',
                          annualEnergyConsumptionSavings:
                          measure?.energyConsumptionSavingsElectricity,
                      annualCarbonEmissionSavings:
                          measure?.annualMeanCarbonEmissionSavings,
                      likeForLikeCost: measure?.likeForLikeCost,
                      incrementalCost: measure?.incrementalCost,
                      totalMeasureCost: measure?.measureCost,
                      totalMeasureCostIntensity: ((measure?.measureCost ?? 0) / (building.grossFloorArea ?? 1)),
                      annualMeanUtilityCostSavings:
                          measure?.annualMeanUtilityCostSavings,
                      annualCarbonTaxSavings: measure?.lifetimeCarbonTaxSavingsMean ?? 0,
                      netPresentValue: measure?.netPresentValueTotal,
                      internalRateOfReturn: measure?.internalRateOfReturnTotal,
                      returnOnInvestment: measure?.returnOnInvestmentTotal,
                      simplePayback: measure?.simplePaybackYearsTotal,
                      marginalAbatementCost: measure?.marginalAbatementCostTotal,
                    //   cashFlowAnnualHidden
                  };
                }
              );
          return measures
      }, [])
    : [];

    // SumKeys and averageKeys are used to determine if value should be summed or averaged. Move keys as needed
    const sumKeys = new Set([
        "annualEnergyConsumptionSavings",
        "annualCarbonEmissionSavings",
        "likeForLikeCost",
        "incrementalCost",
        "annualCarbonTaxSavings",
        "annualMeanUtilityCostSavings",
        "netPresentValue",
        "marginalAbatementCost",
        "cashFlowAnnualHidden"
    ]);

    const averageKeys = new Set([
        "returnOnInvestment",
        "simplePayback",
    ]);

    const accumulateAndAverageData = (data: any[]) => {
        const groupedData: { [key: string]: any[] } = {};

        data.forEach((item) => {
            const type = item.carbonReductionMeasureType;
            if (!groupedData[type]) {
                groupedData[type] = [];
            }
            groupedData[type].push(item);
        });

        const averagedData = Object.keys(groupedData).map((type) => {
            const items = groupedData[type];
            const averagedItem = items.reduce(
                (acc, item) => {
                    Object.keys(item).forEach((key) => {
                        if (key === 'cashFlowAnnualHidden') {
                            if (!acc[key]) acc[key] = [];
                            acc[key] = [...(acc[key] || []), ...(item[key] || [])];
                        } else if (typeof item[key] === "number") {
                            acc[key] = (acc[key] || 0) + item[key];
                        }
                    });
                    return acc;
                },
                { 
                    carbonReductionMeasureType: type,
                    numberOfProjects: items.length 
                }
            );

            Object.keys(averagedItem).forEach((key) => {
                if (typeof averagedItem[key] === "number") {
                    if (sumKeys.has(key)) {
                        return;
                    }
                    if (averageKeys.has(key)) {
                        averagedItem[key] /= items.length;
                    }
                }
            });

            return averagedItem;
        });

        return averagedData;
    };

    const averagedData = useMemo(() => accumulateAndAverageData(formattedData), [formattedData]);


    const tableColumns = useMemo(() => {
        if (!averagedData || averagedData.length === 0) return null;
        const averagedDataKeys = Object.keys(averagedData[0]);

        return averagedDataKeys.map((key) => {
            const typedKey = key as keyof MeasuresMeasuresReportTableRow;

            if (typeof averagedData[0][typedKey] === "string") {
                const { formatFunction, humanReadable, tooltip } =
                    getDisplayInfo(key);

                return {
                    render: formatFunction,
                    key,
                    title: humanReadable,
                    tooltip,
                };
            }

            const columnValues = averagedData.map((row) => row[typedKey]) as number[];

            const { formatFunction, humanReadable, unit, tooltip } =
                getDisplayInfo(key, columnValues);

            return {
                render: formatFunction,
                key,
                title: `${humanReadable} ${unit && `(${unit})`}`,
                tooltip,
            };
        });
    }, [averagedData]);

    const exportColumnsNames = tableColumns?.map((column) => column.title) ?? [];

    const dataSource = useMemo(() => {
        if (!averagedData) return null;

        return averagedData.filter((row) => (row.carbonReductionMeasureType !== "No Action" && row.carbonReductionMeasureType !=="Base building state")).map((row, index) => ({
            ...row,
            key: `${row.carbonReductionMeasureType ? row.carbonReductionMeasureType : 'defaultKey'}-${index}`,
        }));
    }, [averagedData]);

    useEffect(() => {
        // Using preventive state update to avoid a rerender that bugs pagination
        if (dataSource) {
            setExportData((prev: any) => {
                if (JSON.stringify(prev) !== JSON.stringify(dataSource)) {
                    return dataSource;
                }
                return prev;
            });
        }
    
        if (exportColumnsNames) {
            setExportColumnsNames((prev: any) => {
                if (JSON.stringify(prev) !== JSON.stringify(exportColumnsNames)) {
                    return exportColumnsNames;
                }
                return prev;
            });
        }
    }, [dataSource, exportColumnsNames]);

    if (!dataSource || !tableColumns) return null;

    const handleMeasureRowClick = (row: MeasuresMeasuresReportTableRow) => {

        // Uncomment when reimplimenting the Cash Flow modal  
        // setSelectedRow(row);
        // setModalIsOpen(true);
    };
    
    return (
        <div className="measures-measures-report">
            <ComponentErrorBoundary
                fallback={errorFallbackUI("tableError")}
                originComponent="MeasuresMeasuresReport"
            >
                <Table
                    dataSource={dataSource}
                    columns={tableColumns}
                    className="measures-report-table"
                    pagination={true}
                    onRowClick={(row) => handleMeasureRowClick(row)}
                />

            <MeasuresReportModal
                modalIsOpen={modalIsOpen}
                onCancel={handleCancel}
                rowData={selectedRow}
            />
            </ComponentErrorBoundary>
        </div>    
        )
}

export default MeasuresMeasuresReport;