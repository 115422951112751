import Card from "components/molecules/Card";
import { useCurrentAnnualCarbonEmissionsCard } from "queries/Reports/Baseline/useCurrentAnnualCarbonEmissionsCard";
import { useMemo } from "react";

function CurrentAnnualCarbonEmissionsCard() {
    const { data, error, isLoading } = useCurrentAnnualCarbonEmissionsCard();

    const cardData = useMemo(() => {
        if (!data) return undefined;

        const { totalCarbonEmissions, averageEmissionsIntensity } = data;
        if (!totalCarbonEmissions || !averageEmissionsIntensity)
            throw new Error(
                "averageEmissionsIntensity and averageEmissionsIntensity should be defined"
            );

        return {
            header: {
                value: totalCarbonEmissions,
                displayInfoKey: "carbonEmissions",
            },
            sections: [
                [
                    {
                        value: averageEmissionsIntensity,
                        displayInfoKey: "carbonEmissionIntensity",
                    },
                ],
            ],
        };
    }, [data]);

    return (
        <Card data={cardData} loading={isLoading} error={error?.toString()} />
    );
}

export default CurrentAnnualCarbonEmissionsCard;