import AnnualCashFlowChart from "components/organisms/Charts/AnnualCashFlowChart";
import { useAnnualCashFlowChart } from "queries/BuildingReport/Project/useAnnualCashFlowChart";
import { useMemo } from "react";
import { UseQueryResult } from "react-query";

interface AnnualCashFlowChartProps {
    carbonReductionMeasureType: string;
    selected: "total" | "incremental";
}

function CashFlowChartWrapper({
    carbonReductionMeasureType,
    selected,
}: AnnualCashFlowChartProps) {
    const useAnnualCashFlowChartQuery = () => {
        const { data, isLoading, ...remainder } = useAnnualCashFlowChart(
            carbonReductionMeasureType
        );

        const chartData = useMemo(() => {
            if (data === undefined) return undefined;
            return data[selected];
        }, [selected, data]);

        return {
            data: chartData,
            isLoading: isLoading || (!chartData && !remainder.isError),
            ...remainder,
        };
    };

    const tableKeys = useMemo(
        () => buildCashFlowTableColumnKeys(selected),
        [selected]
    );

    return (
        <AnnualCashFlowChart
            query={useAnnualCashFlowChartQuery as () => UseQueryResult<any>}
            tableColummnKeys={tableKeys}
            barKeys={buildCashFlowChartKeys(selected)}
        />
    );
}

const buildCashFlowTableColumnKeys = (selected: "total" | "incremental") => {
    const keys = [
        "calendarYear",
        "likeForLikeCost",
        "incrementalCost",
        "utilityCostSavingsNaturalGas",
        "utilityCostSavingsElectricity",
        "carbonTaxSavings",
        "cashFlowAnnual",
    ];
    if (selected === "incremental") {
        keys.splice(1, 1);
    }
    return keys;
};

const buildCashFlowChartKeys = (selected: "total" | "incremental") => {
    const keys = [
        "likeForLikeCost",
        "incrementalCost",
        "utilityCostSavingsNaturalGas",
        "utilityCostSavingsElectricity",
        "carbonTaxSavings",
    ];
    if (selected === "incremental") {
        keys.splice(0, 1);
    }
    return keys;
};

export default CashFlowChartWrapper;