import { OperatingCostCard } from "components/organisms/Charts";
import { useAnnualOperatingCostCard } from "queries/Reports/Baseline/useAnnualOperatingCostCard";
import { useMemo } from "react";

function AnnualOperatingCostCard({ selectedYear }: { selectedYear: number }) {
    const { data, isLoading, error } = useAnnualOperatingCostCard();

    const dataForYear = useMemo(() => {
        if (!data) return undefined;
        return data.find((datum) => datum?.year === selectedYear);
    }, [selectedYear, data]);

    return (
        <OperatingCostCard
            data={dataForYear}
            cumulative={true}
            loading={isLoading}
            error={error?.toString()}
        />
    );
}

export default AnnualOperatingCostCard;