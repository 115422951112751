import { BaselineAreaChart } from "components/organisms/Charts";
import { useAnnualCarbonEmissionsChart } from "queries/Reports/Baseline/useAnnualCarbonEmissionsChart";
import { useMemo } from "react";

function AnnualCarbonEmissionsChart() {
    const { data, isLoading, error } = useAnnualCarbonEmissionsChart();
    const areaChartData = useMemo(
        () =>
            data?.map((datum) => ({
                areaY: datum?.totalCarbonEmissions || 0,
                x: datum?.year || 0,
            })),
        [data]
    );
    return (
        <BaselineAreaChart
            data={areaChartData}
            loading={isLoading}
            error={error?.toString()}
        />
    );
}

export default AnnualCarbonEmissionsChart;