import { useState } from "react";

import ViewTab from "components/atoms/ViewTab";
import { Header, Paragraph } from "components/atoms/Typography";
import { ExportIcon, FilterIcon } from "components/atoms/Icon";
import FiltersSidebar from "components/organisms/FiltersSidebar";
import CsvDownloadButton from 'react-json-to-csv'
import MeasuresProjectsReport from "./MeasuresProjectsReport";

import "./MeasuresPage.scss";
import MeasuresMeasuresReport from "./MeasuresMeasuresReport";

interface MeasuresPageProps {
    filtersetId: string | null;
    setFiltersetId: (filtersetId: string | null) => void;
}

function MeasuresPage({ 
    filtersetId, setFiltersetId 
}: MeasuresPageProps) {
    const [currentActiveTab, setCurrentActiveTab] =
        useState<ReportTab>("projects");

    const [filterSidebarIsOpen, setFilterSidebarIsOpen] = useState(false);
    // const [filtersetId, setFiltersetId] = useState<string | null>(null);
    
    const [exportProjectsData, setExportProjectsData] = useState([]);
    const [exportProjectsColumnsNames, setExportProjectsColumnsNames] = useState([]);

    const [exportMeasuresData, setExportMeasuresData] = useState([]);
    const [exportMeasuresColumnsNames, setExportMeasuresColumnsNames] = useState([]);

    return (
        <div className="measures-page">
            <div className="measures-page--view-tabs">
                <div className="measures-page--view-tabs">
                    <ViewTab
                        id="measures-projects-tab"
                        name="Projects"
                        selected={currentActiveTab === "projects"}
                        onClick={() => setCurrentActiveTab("projects")}
                    />
                    <ViewTab
                        id="measures-measures-tab"
                        name="Measures"
                        selected={currentActiveTab === "measures"}
                        onClick={() => setCurrentActiveTab("measures")}
                    />
                </div>
                <div className="measures-page-filter-with-export">
                    {currentActiveTab === "projects" &&
                        <CsvDownloadButton
                            data={currentActiveTab === "projects" ? exportProjectsData : []}
                            filename={`${currentActiveTab}-data.csv`}
                            headers={currentActiveTab === "projects" ? exportProjectsColumnsNames : []}
                        >
                            <div className="measures-page-flex-container">
                                <ExportIcon />
                                <Paragraph>Export</Paragraph>
                            </div>
                        </CsvDownloadButton>
                    }
                    {currentActiveTab === "measures" &&
                        <CsvDownloadButton
                            data={currentActiveTab === "measures" ? exportMeasuresData : []}
                            filename={`${currentActiveTab}-data.csv`}
                            headers={currentActiveTab === "measures" ? exportMeasuresColumnsNames : []}
                        >
                            <div className="measures-page-flex-container">
                                <ExportIcon />
                                <Paragraph>Export</Paragraph>
                            </div>
                        </CsvDownloadButton>
                    }
                </div>
            </div>
            <FiltersSidebar
                closeSidebar={() => setFilterSidebarIsOpen(false)}
                sidebarOpen={filterSidebarIsOpen}
                page="measures" 
                setFiltersetId={setFiltersetId}         
            />

            {currentActiveTab === "measures" &&
                <MeasuresMeasuresReport 
                filtersetId={filtersetId}
                setExportData={setExportMeasuresData}
                setExportColumnsNames={setExportMeasuresColumnsNames}
                />
            }

            {currentActiveTab === "projects" &&
                <MeasuresProjectsReport 
                    filtersetId={filtersetId}
                    setExportData={setExportProjectsData}
                    setExportProjectsColumnsNames={setExportProjectsColumnsNames}
                />
            }
        </div>
    );
}

export default MeasuresPage;
