import { MenuItem } from "@mui/material";
import { Modal, Select } from "antd";
import { Button } from "components/atoms/Button";
import { Paragraph } from "components/atoms/Typography";
import { useState } from "react";
import { useCreateCustomerAccount } from "mutations/AccountManagement/useCreateCustomerAccount";
import styles from "./CreateOrganizationModal.module.scss";

interface CreateOrganizationModalProps {
    modalIsOpen: boolean;
    onCancel: () => void;
    setModalIsOpen: (isOpen: boolean) => void;
}

function CreateOrganizationModal({
    modalIsOpen,
    onCancel,
    setModalIsOpen
}: CreateOrganizationModalProps) {

    const [organizationName, setOrganizationName] = useState("");
    const [currency, setCurrency] = useState("CAD");
    const [errorMessage, setErrorMessage] = useState("");
    const [hasTriedToSubmit, setHasTriedToSubmit] = useState(false);

    const { mutate } = useCreateCustomerAccount();
    
    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const name = e.target.value;
        setOrganizationName(name);

        if (hasTriedToSubmit && name.trim()) {
            setErrorMessage('');
        }
    };

    const handleCurrencyChange = (value: string) => {
        setCurrency(value);
    };

    const modalSubmitHandler = () => {
        setHasTriedToSubmit(true); 

        if (!organizationName.trim()) {
            setErrorMessage("Organization name cannot be empty.");
            return;
        }

        mutate(
            { name: organizationName, currencyCode: currency },
            {
              onSuccess: () => {
                setModalIsOpen(false);
              }
            }
          );
    }

    return (
        <Modal
            open={modalIsOpen}
            onCancel={onCancel}
            title="New organization"
            className={styles.modalContainer}
            footer={
                <CreateOrganizationModalFooter
                    onSave={() => modalSubmitHandler()}
                    onCancel={onCancel}
                    disableSaveButton={!organizationName.trim()}
                />
            }
        >
            <div>
                <h3 className={`${styles.inputHeader} ${styles.firstHeader}`}>Organization Name</h3>

                <div className="form-input-wrapper">
                    <div>
                        <input
                            style={{
                                width: "100%",
                            }}
                            placeholder="Organization name"
                            value={organizationName}
                            className="form-input"
                            onChange={handleNameChange}
                        />
                    </div>

                    <div>
                        <h3 className={styles.inputHeader}>Currency</h3>
                        <Select
                            defaultValue="CAD"
                            value={currency}
                            style={{ width: "100%" }}
                            onChange={handleCurrencyChange}
                        >
                            <MenuItem value="CAD">
                                CAD
                            </MenuItem>
                            <MenuItem value="USD">
                                USD
                            </MenuItem>
                        </Select>
                    </div>

                {errorMessage && (
                    <Paragraph
                        size="small"
                        style={{ color: "var(--audette-content-negative)" }}
                    >
                        {errorMessage}
                    </Paragraph>
                )}

                        
            
                </div>
            </div>
        </Modal>
    );
}

interface CreateOrganizationModalFooterProps {
    onSave: () => void;
    onCancel: () => void;
    disableSaveButton: boolean;
}

function CreateOrganizationModalFooter({
    onSave,
    onCancel,
    disableSaveButton,
}: CreateOrganizationModalFooterProps) {
    return (
        <div className="bulk-edit-tags__footer" style={{ marginTop: "40px" }}>
            <Button 
                type="primary" 
                onClick={onSave}
                disabled={disableSaveButton}    
            >
                Create
            </Button>
            <Button
                type="link"
                onClick={onCancel}
                style={{ color: "var(--audette-black)" }}
            >
                Cancel
            </Button>
        </div>
    );
}

export default CreateOrganizationModal;
