import Card from "components/molecules/Card";
import { useCurrentAnnualEnergyConsumptionCard } from "queries/Reports/Baseline/useCurrentAnnualEnergyConsumptionCard";
import { useMemo } from "react";

function CurrentAnnualEnergyConsumptionCard() {
    const { data, error, isLoading } = useCurrentAnnualEnergyConsumptionCard();

    const cardData = useMemo(() => {
        if (!data) return undefined;

        const { totalEnergyConsumption, averageEnergyIntensity } = data;

        return {
            header: {
                value: totalEnergyConsumption,
                displayInfoKey: "energyConsumption",
            },
            sections: [
                [
                    {
                        value: averageEnergyIntensity,
                        displayInfoKey: "energyUseIntensity",
                    },
                ],
            ],
        };
    }, [data]);

    return (
        <Card data={cardData} loading={isLoading} error={error?.toString()} />
    );
}

export default CurrentAnnualEnergyConsumptionCard;