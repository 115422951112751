import { useMemo } from "react";
import { Table, DataSource } from "components/molecules/Table";
import { getDisplayInfo } from "utils/formatting";
import DataCoverageLine from "components/molecules/DataCoverageLine";

interface FundsProps {
    funds: Fund[];
}

function Funds({ funds }: FundsProps) {
    const dataSource: DataSource[] = useMemo(
        () =>
            funds.map((fund) => ({
                ...fund,
                key: fund.fundId,
                buildingCount: fund.properties.reduce(
                    (count, property) => count + property.buildingModels.length,
                    0
                ),
                propertyCount: fund.properties.length,
                tags: getTagsDisplayValue(fund),
                dataCoverages: (
                    <DataCoverageLine
                        map={getDataCoverageMap(fund.properties)}
                    />
                ),
            })),
        [funds]
    );

    if (dataSource.length === 0) {
        return null;
    }

    const columns = useMemo(
        () =>
            tableColumns.map((columnKey) => {
                const { formatFunction, humanReadable, unit, tooltip } =
                    typeof dataSource[0][columnKey] === "number"
                        ? getDisplayInfo(
                              columnKey,
                              dataSource.map((d) => d[columnKey]) as number[]
                          )
                        : getDisplayInfo(columnKey);

                return {
                    title: `${humanReadable} ${unit && `(${unit})`}`,
                    dataIndex: columnKey,
                    key: columnKey,
                    render: (value: any) => {
                        if (value === null || value === undefined) return "";
                        return formatFunction(value);
                    },
                    tooltip,
                };
            }),
        [dataSource]
    );

    return <Table dataSource={dataSource} columns={columns} />;
}

const getTagsDisplayValue = (fund: Fund) => {
    const tags: string[] = [];
    fund.properties.forEach((property) => {
        property.buildingModels.forEach((building) => {
            tags.push(...building.tags);
        });
    });
    const uniqueTags = new Set([...tags]);
    return Array.from(uniqueTags);
};

const getDataCoverageMap = (funds: FundProperty[]) => {
    const map = new Map<DataCoverageEnum | "total", number>();
    let totalBuildings = 0;

    funds.forEach((fund) => {
        totalBuildings += fund.buildingModels.length;
        fund.buildingModels.forEach(({ dataCoverage }) => {
            const identifier = dataCoverage.dataCoverage;
            if (identifier == null) return;
            const currentIdentifierTotal = map.get(identifier) || 0;
            map.set(identifier, currentIdentifierTotal + 1);
        });
    });

    map.set("total", totalBuildings);

    return map;
};

const tableColumns = [
    "fundName",
    "dataCoverages",
    "tags",
    "propertyCount",
    "buildingCount",
    "grossFloorArea",
    "carbonEmissionReductionPotential",
    "averageMarginalAbatementCost",
    "netPresentValueIntensity",
    "annualUtilityCostIntensityCurrent",
    "annualCarbonTaxIntensityCurrent",
    "annualCarbonEmissionIntensityCurrent",
    "annualEnergyUseIntensityCurrent",
];

export default Funds;
