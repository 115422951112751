import { OperatingCostCard } from "components/organisms/Charts";
import { useCumulativeOperatingCostCard } from "queries/Reports/Baseline/useCumulativeOperatingCostCard";

function CumulativeOperatingCostCard() {
    const { data, isLoading, error } = useCumulativeOperatingCostCard();
    return (
        <OperatingCostCard
            data={data}
            loading={isLoading}
            cumulative={true}
            error={error?.toString()}
        />
    );
}

export default CumulativeOperatingCostCard;