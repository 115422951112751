import { FutureProjectionCarbonEmissionsCard } from "components/organisms/Charts";
import { useCumulativeCarbonEmissionsCard } from "queries/Reports/Baseline/useCumulativeCarbonEmissionsCard";

function CumulativeCarbonEmissionsCard() {
    const { data, isLoading, error } = useCumulativeCarbonEmissionsCard();
    return (
        <FutureProjectionCarbonEmissionsCard
            data={data}
            cumulative={true}
            loading={isLoading}
            error={error?.toString()}
        />
    );
}

export default CumulativeCarbonEmissionsCard;