import { createContext, ReactNode, useEffect, useMemo, useState } from "react";
import { Auth0Provider } from "@auth0/auth0-react";

export const AccessTokenContext = createContext<string | null>(null);

interface AccessTokenProviderProps {
    accessToken: string | null;
    children: ReactNode;
}

export function AccessTokenProvider({
    accessToken,
    children,
}: AccessTokenProviderProps) {
    return (
        <AccessTokenContext.Provider value={accessToken}>
            {children}
        </AccessTokenContext.Provider>
    );
}

interface AuthProviderProps {
    children: ReactNode;
}

export function AuthProvider({ children }: AuthProviderProps) {
    return (
        <Auth0Provider
            domain="audette.auth0.com"
            clientId="PNyAKFmjc1M6Q6tY1VLKpvt5oG26SVZA"
            redirectUri={window.location.origin}
            audience="https://portfolio.audette.io"
            scope="openid email"
        >
            {children}
        </Auth0Provider>
    );
}

export enum AlertStatus {
    Success = "success",
    Error = "error",
    None = "none",
}
export interface AlertContextValue {
    status: AlertStatus;
    message: string | null;
    success: (text: string) => void;
    error: (text: string) => void;
    clear: () => void;
}
const defaultAlert: AlertContextValue = {
    status: AlertStatus.None,
    message: "",
    success: () => {},
    error: () => {},
    clear: () => {},
};

export const AlertContext = createContext<AlertContextValue>(defaultAlert);

interface AlertProviderProps {
    children: ReactNode;
}

export function AlertProvider({ children }: AlertProviderProps) {
    const [status, setStatus] = useState<AlertStatus>(AlertStatus.None);
    const [message, setMessage] = useState<string | null>(null);

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;
        if (status === AlertStatus.Success) {
            timeoutId = setTimeout(() => {
                setStatus(AlertStatus.None);
                setMessage(null);
            }, 5000);
        }
        return () => clearTimeout(timeoutId);
    }, [status]);

    const value = useMemo(
        () => ({
            status,
            message,
            success: (text: string) => {
                setMessage(text);
                setStatus(AlertStatus.Success);
            },
            error: (text: string) => {
                setMessage(text);
                setStatus(AlertStatus.Error);
            },
            clear: () => setStatus(AlertStatus.None),
        }),
        [status, message]
    );

    return (
        <AlertContext.Provider value={value}>{children}</AlertContext.Provider>
    );
}
