import { EndUseSankey } from "components/organisms/Charts";
import { buildSankeyData } from "components/organisms/Charts/EndUseSankey";
import { useCurrentAnnualEndUseChart } from "queries/Reports/Baseline/useCurrentAnnualEndUseChart";
import { useMemo } from "react";

function CurrentAnnualEndUseChart() {
    const { data, isLoading, error } = useCurrentAnnualEndUseChart();

    const formattedData = useMemo(() => {
        if (!data) return null;
        const { carbonEmissions, energyConsumption, utilityCost } = data;
        return {
            carbonEmissions: buildSankeyData(carbonEmissions),
            energyConsumption: buildSankeyData(energyConsumption),
            utilityCost: buildSankeyData(utilityCost),
        };
    }, [data]);

    return (
        <EndUseSankey
            data={formattedData}
            loading={isLoading}
            error={error?.toString()}
        />
    );
}

export default CurrentAnnualEndUseChart;