export const KEY_TO_COLOR_MAP: { [key: string]: string } = {
    cashFlowCumulative: "#EB03AD",
    carbonTaxSavings: "#50E253",
    incrementalCost: "#D5BBF9",
    likeForLikeCost: "#7A2AEB",
    utilityCostSavingsElectricity: "#4BF7B5",
    utilityCostSavingsNaturalGas: "#00BC98",
    totalCarbonTaxSavings: "#50E253",
    totalIncrementalCost: "#D5BBF9",
    totalUtilityCostSavings: "#00BC98",
};

export const MARGIN = {
    bottom: 25,
    left: 100,
    top: 10,
    right: 50,
};

export const LINE_KEY = "Net cash flow";