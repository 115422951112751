import { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { LogOutIcon, PlusIcon } from "components/atoms/Icon";
import { Button } from "components/atoms/Button";
import posthog from "posthog-js";
import HoverableDropdown from "components/atoms/HoverableDropdown/HoverableDropdown";
import { useUpdateActiveOrganization } from "mutations/useUpdateActiveOrganization";
import CreateOrganizationModal from "components/molecules/CreateOrganizationModal/CreateOrganizationModal";
import { useUser } from "hooks/useUser";
import { UserPermissions } from "utils/enums";
import { Paragraph } from "../../atoms/Typography";
import styles from "./HeaderDropDownMenu.module.scss";

interface HeaderDropdownMenuProps {
    userEmail: string;
    customerAccounts: CustomerAccount[];
    activeOrganization: string;
}

function HeaderDropdownMenu({
    userEmail,
    customerAccounts,
    activeOrganization,
}: HeaderDropdownMenuProps) {
    const { logout } = useAuth0();
    const { data: user } = useUser();
    const { mutate } = useUpdateActiveOrganization();
    
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const permissions = user?.permissions || [""];


    const handleOrganizationChange = (newActiveOrganizationUid: string) => {
        const newActiveOrganizationUidObject = {
            activeOrganizationUid: newActiveOrganizationUid
        };
        mutate(newActiveOrganizationUidObject);
    };

    const handleNewOrganization = () => {
        setModalIsOpen(true);
    };

    const handleCancel = () => {
        setModalIsOpen(false);
    }    

    return (
        <div>
            <HoverableDropdown
                userEmail={userEmail ? userEmail[0].toUpperCase() : ""}
                customerAccounts={customerAccounts}
                activeOrganization={activeOrganization}
                dropdownElements={
                    <>
                        {customerAccounts
                            .sort((a, b) => a.organizationName.localeCompare(b.organizationName))
                            .map((customerAccount, index) => (
                                <Button
                                    className={`
                                                headerButton 
                                                headerCustomerAccount
                                                ${
                                                    customerAccount.organizationName ===
                                                    activeOrganization
                                                    // eslint-disable-next-line
                                                        ? styles.headerActiveCustomerAccountContainer
                                                        : ""
                                                }
                                                ${
                                                    index ===
                                                    customerAccounts.length - 1
                                                        ? "headerCustomerAccountLast"
                                                        : ""
                                                }    
                                            `}
                                    key={customerAccount.organizationUid}
                                    onClick={() =>
                                        handleOrganizationChange(
                                            customerAccount.organizationUid
                                        )
                                    }
                                >
                                    <span>{customerAccount.organizationName}</span>
                                    {customerAccount.organizationName ===
                                        activeOrganization && (
                                        <span
                                            className={
                                                styles.headerActiveCustomerAccount
                                            }
                                        >
                                            Signed In
                                        </span>
                                    )}
                                </Button>
                        ))}

                        {permissions.includes(UserPermissions.CreateCustomerAccount) && (
                            <Button
                                id="nav-bar-sign-out-button"
                                className="headerButton"
                                onClick={handleNewOrganization}
                            >
                                <Paragraph className="logOutContainer">
                                    <PlusIcon />
                                    New organization
                                </Paragraph>
                            </Button>
                        )}

                        <Button
                            id="nav-bar-sign-out-button"
                            onClick={() => {
                                logout({
                                    returnTo: window.location.origin,
                                });
                                posthog.capture("User signed out");
                            }}
                            className="headerButton"
                        >
                            <Paragraph className="logOutContainer">
                                <LogOutIcon />
                                Sign Out
                            </Paragraph>
                        </Button>
                    </>
                }
            />
            <CreateOrganizationModal 
                modalIsOpen={modalIsOpen} 
                onCancel={handleCancel}    
                setModalIsOpen={setModalIsOpen}
            />
        </div>
    );
}

export default HeaderDropdownMenu;
