import queryFilteredBuildings from "ApiLayer/Building/queryFilteredBuildings";
import { useAccessToken } from "hooks/useAccessToken";
import useAlert from "hooks/useAlert";
import { useQuery } from "react-query";
import { QUERY_STALE_TIME } from "utils/constants";
import { showMutationAlert } from "utils/helpers";

export const filteredBuildingsQueryKey = ["filteredBuildings"];

export const    useFilteredBuildings = (reportFiltersetUid: string | null) => {
    const accessToken = useAccessToken();
    const alert = useAlert();

    return useQuery({
        queryKey: [...filteredBuildingsQueryKey, reportFiltersetUid], // Note: including reportFiltersetUid in the key so React Query caches results uniquely for each reportFiltersetUid
        staleTime: QUERY_STALE_TIME,
        queryFn: async () => queryFilteredBuildings(
            accessToken,
            { reportFiltersetUid }
        ),
        onError: (error) => {
            showMutationAlert(error, alert);
        },
    });
};