import { FutureProjectionCarbonEmissionsCard } from "components/organisms/Charts";
import { useAnnualCarbonEmissionsCard } from "queries/Reports/Baseline/useAnnualCarbonEmissionsCard";
import { useMemo } from "react";

function AnnualCarbonEmissionsCard({ selectedYear }: { selectedYear: number }) {
    const { data, isLoading, error } = useAnnualCarbonEmissionsCard();

    const dataForYear = useMemo(() => {
        if (!data) return undefined;
        return data.find((datum) => datum?.year === selectedYear);
    }, [selectedYear, data]);

    return (
        <FutureProjectionCarbonEmissionsCard
            data={dataForYear}
            cumulative={true}
            loading={isLoading}
            error={error?.toString()}
        />
    );
}

export default AnnualCarbonEmissionsCard;