import { ReactNode, useEffect, useMemo, useState } from "react";

// Components
import { Table } from "components/molecules/Table";
import TableLoading from "components/molecules/TableLoading";
import { useCarbonReductionMeasuresTable } from "queries/BuildingReport/Projects/useCarbonReductionMeasuresTable";
import {
    BackArrow,
    HandHoldingMoneyIcon,
    ImplementationIcon,
    LeafIcon,
    MeasureTypeIcon,
    SizingIcon,
    UnitCostIcon,
} from "components/atoms/Icon";
import { Header, Paragraph } from "components/atoms/Typography";
import { Button } from "components/atoms/Button";
import {
    CardErrorState,
    ChartErrorState,
    ComponentErrorBoundary,
} from "components/molecules/ErrorStates";
import { ErrorPage } from "pages/ErrorState";
import ChartContainer from "components/molecules/ChartContainer";
import { ResponsiveStream } from "@nivo/stream";

// hooks
import posthog from "posthog-js";
import { useImpactCards } from "queries/BuildingReport/Project/useImpactCards";
import { useTotalCostAndSavingsCard } from "queries/BuildingReport/Project/useTotalCostAndSavingsCard";
import { useMeasureReportDecarbonizationForecastChart } from "queries/BuildingReport/Project/useMeasureReportDecarbonizationForecastChart";
import { useTotalCarbonEmissionsCard } from "queries/BuildingReport/Project/useTotalCarbonEmissionsCard";
import { useTotalEnergyConsumptionCard } from "queries/BuildingReport/Project/useTotalEnergyConsumptionCard";
import { useHeader } from "queries/BuildingReport/Project/useHeader";

// helpers
import classNames from "classnames";
import {
    displayValueWithUnit,
    formatPercentage,
    getDisplayInfo,
    getTextColorByValue,
} from "utils/formatting";
import { capitalizeFirstLetter } from "utils/helpers";

// constants
import { theme } from "components/molecules/Charts/ChartTheme";
import { CURRENT_YEAR } from "utils/constants";

// styling
import "./ProjectsReport.scss";
import useBuildingLoadingState from "recoilStore/useBuildingLoadingState";
import { useBuildingModelUid } from "hooks/useBuildingModelUid";
import { InlineBoldParagraph } from "components/atoms/Typography/Typography";
import errorFallbackUI from "components/molecules/ErrorFallbackUI.tsx/ErrorFallbackUI";
import CashFlowChartWrapper from "./Widget/CashFlowChartWrapper";

interface ProjectsReportProps{
    carbonReductionMeasureTypeUrl?: string | null,
    carbonReductionMeasureCategoryUrl?: string | null
}


function ProjectsReport({
    carbonReductionMeasureTypeUrl,
    carbonReductionMeasureCategoryUrl
}: ProjectsReportProps) {
    const { data, isLoading, error } = useCarbonReductionMeasuresTable();

    const buildingModelUid = useBuildingModelUid();
    const { loadingState } = useBuildingLoadingState(buildingModelUid);
    useEffect(() => {
        if (loadingState.buildingReportsLoading) {
            setActiveProjectName(undefined);
        }
    }, [loadingState.buildingReportsLoading]);

    const [activeProjectName, setActiveProjectName] = useState<string | undefined>(carbonReductionMeasureTypeUrl ?? undefined);
    const [activeProjectCategory, setActiveProjectCategory] =
        useState<string | undefined>(carbonReductionMeasureCategoryUrl ?? undefined);

    const handleRowClick = (row: ProjectsReportTableRow) => {
        setActiveProjectName(row.carbonReductionMeasureType);
        setActiveProjectCategory(row.carbonReductionMeasureCategory);
        posthog.capture("projects_table_row_clicked", {
            project: row.carbonReductionMeasureType,
        });
    };

    const tableColumns = useMemo(() => {
        if (!data) return null;
        if (data.length === 0) return [];
        return tableColumnKeys.map((key) => {
            if (typeof data[0][key] === "string") {
                const { formatFunction, humanReadable, tooltip } =
                    getDisplayInfo(key);

                return {
                    render: formatFunction,
                    key,
                    title: humanReadable,
                    tooltip,
                };
            }
            const columnValues = data.map((row) => row[key]) as number[];

            const { formatFunction, humanReadable, unit, tooltip } =
                getDisplayInfo(key, columnValues);

            return {
                render: formatFunction,
                key,
                title: `${humanReadable} ${unit && `(${unit})`}`,
                tooltip,
            };
        });
    }, [data]);

    const dataSource = useMemo(() => {
        if (!data) return null;
        return data.map((row) => ({
            ...row,
            key: row.carbonReductionMeasureType,
        }));
    }, [data]);

    if (isLoading || loadingState.buildingReportsLoading)
        return <TableLoading includeHeader={false} />;
    if (error) return <ErrorPage />;

    if (!dataSource || !tableColumns) return null;

    if (activeProjectName && activeProjectCategory)
        return (
            <ProjectReport
                handleBackClick={() => setActiveProjectName(undefined)}
                carbonReductionMeasureType={activeProjectName}
                carbonReductionMeasureCategory={activeProjectCategory}
            />
        );

    return (
        <div className="projects-report">
            <ComponentErrorBoundary fallback={errorFallbackUI("tableError")} originComponent="ProjectsReport">
                <Table
                    dataSource={dataSource}
                    columns={tableColumns}
                    className="projects-report-table"
                    pagination={false}
                    onRowClick={handleRowClick}
                />
            </ComponentErrorBoundary>
        </div>
    );
}

const tableColumnKeys: (keyof ProjectsReportTableRow)[] = [
    "carbonReductionMeasureType",
    "carbonReductionMeasureCategory",
    "yearApplied",
    "annualEnergyConsumptionSavings",
    "annualCarbonEmissionSavings",
    "likeForLikeCost",
    "incrementalCost",
    "totalMeasureCost",
    "totalMeasureCostIntensity",
    "annualEnergyCostSavings",
    "annualCarbonTaxSavings",
    "netPresentValue",
    "internalRateOfReturn",
    "returnOnInvestment",
    "simplePayback",
    "marginalAbatementCost",
];

interface ProjectReportProps {
    handleBackClick: () => void;
    carbonReductionMeasureType: string;
    carbonReductionMeasureCategory: string;
}

export function ProjectReport({
    handleBackClick,
    carbonReductionMeasureType,
    carbonReductionMeasureCategory,
}: ProjectReportProps) {
    return (
        <div className="project-report">
            <ProjectReportHeader
                carbonReductionMeasureType={carbonReductionMeasureType}
                handleBackClick={handleBackClick}
            />
            <FinancialImpact
                carbonReductionMeasureType={carbonReductionMeasureType}
            />
            <PerformanceImpact
                carbonReductionMeasureCategory={carbonReductionMeasureCategory}
                carbonReductionMeasureType={carbonReductionMeasureType}
            />
        </div>
    );
}

interface ProjectReportHeaderProps {
    carbonReductionMeasureType: string;
    handleBackClick: () => void;
}

function ProjectReportHeader({
    carbonReductionMeasureType,
    handleBackClick,
}: ProjectReportHeaderProps) {
    const { isLoading, isError, data } = useHeader(carbonReductionMeasureType);

    if (isError)
        return (
            <div className="project-report-header">
                <div
                    role="button"
                    className="project-report__back-button"
                    onClick={handleBackClick}
                    onKeyDown={handleBackClick}
                    tabIndex={0}
                >
                    <BackArrow />
                    <Header
                        style={{ color: "var(--audette-gray-600)" }}
                        size="small"
                    >
                        Back to projects
                    </Header>
                </div>
                <Header size="medium"> {carbonReductionMeasureType}</Header>
            </div>
        );

    if (isLoading || !data)
        return (
            <div className="project-report-header--loading">
                <div className="loading" style={{ width: "20%" }} />
                <div className="loading" style={{ width: "12%" }} />
                <div className="loading" style={{ width: "13%" }} />
                <div className="loading" style={{ width: "11%" }} />
            </div>
        );

    return (
        <div className="project-report-header">
            <div
                role="button"
                className="project-report__back-button"
                onClick={handleBackClick}
                onKeyDown={handleBackClick}
                tabIndex={0}
            >
                <BackArrow />
                <Header
                    style={{
                        color: "var(--audette-gray-600)",
                    }}
                    size="small"
                >
                    Back to projects
                </Header>
            </div>
            <div>
                <Header size="medium" style={{ marginBottom: "1rem" }}>
                    {carbonReductionMeasureType}
                </Header>

                <div className="project-report-header__row">
                    {buildHeaderTitleAndUnitsStruct(data).map(
                        ({ title, titleValue, icon, unit }) => (
                            <ProjectReportHeaderAttribute
                                key={title}
                                title={title}
                                titleValue={titleValue}
                                unit={unit}
                                icon={icon}
                            />
                        )
                    )}
                </div>
            </div>
            {measureNameToDescriptionMap[carbonReductionMeasureType] && (
                <Paragraph size="small" style={{ maxWidth: "40%" }}>
                    {measureNameToDescriptionMap[carbonReductionMeasureType]}
                </Paragraph>
            )}
        </div>
    );
}

const buildHeaderTitleAndUnitsStruct = (data: MeasureReportHeader) => {
    const { formatFunction: formatSize } = getDisplayInfo(
        "carbonReductionMeasureSize"
    );

    const { formatFunction: formatUnitCost, unit: costUnit } = getDisplayInfo(
        "carbonReductionMeasureUnitCost"
    );

    const options: ProjectReportHeaderAttributeProps[] = [
        {
            title: "Type",
            titleValue: data.carbonReductionMeasureCategory,
            icon: <MeasureTypeIcon />,
        },
        {
            title: "Implementation",
            titleValue: `${data.yearApplied} (${data.carbonReductionMeasureLife} yrs)`,
            icon: <ImplementationIcon />,
        },
    ];

    if (
        data.carbonReductionMeasureSize !== undefined &&
        data.carbonReductionMeasureSizeUnit
    )
        options.push({
            title: "Sizing",
            titleValue: formatSize(data.carbonReductionMeasureSize),
            icon: <SizingIcon />,
            unit: ` ${getCarbonReductionMeasureSizeUnitHumanReadableString(
                data.carbonReductionMeasureSizeUnit,
                data.carbonReductionMeasureSize > 1 ? "plural" : "singular"
            )}`,
        });

    if (
        data.carbonReductionMeasureUnitCost !== undefined &&
        data.carbonReductionMeasureSizeUnit
    )
        options.push({
            title: "Unit cost",
            titleValue: displayValueWithUnit(
                data.carbonReductionMeasureUnitCost,
                costUnit,
                (v) => formatUnitCost(v)
            ),
            icon: <UnitCostIcon />,
            unit: `/${getCarbonReductionMeasureSizeUnitHumanReadableString(
                data.carbonReductionMeasureSizeUnit,
                "singular"
            )}`,
        });

    return options;
};

const getCarbonReductionMeasureSizeUnitHumanReadableString = (
    unit: string,
    singularOrPlural: "singular" | "plural"
) => {
    const humanReadable = measureSizeUnitToHumanReadableSingular[unit];

    if (singularOrPlural === "singular") return humanReadable || unit;
    return measureSizeUnitSingularPluralMap[unit] || humanReadable || unit;
};

interface ProjectReportHeaderAttributeProps {
    title: string;
    titleValue: string;
    icon: ReactNode;
    unit?: string;
}

function ProjectReportHeaderAttribute({
    title,
    titleValue,
    icon,
    unit,
}: ProjectReportHeaderAttributeProps) {
    return (
        <div className="project-report-header__measure_attribute">
            {icon}
            <Header size="small">{title}:</Header>
            <Paragraph size="regular">{`${titleValue}${unit || ""}`}</Paragraph>
        </div>
    );
}

interface FinancialImpactProps {
    carbonReductionMeasureType: string;
}

function FinancialImpact({ carbonReductionMeasureType }: FinancialImpactProps) {
    const [selectedView, setSelectedView] = useState<"total" | "incremental">(
        "total"
    );

    return (
        <div className="financial-impact project-report__section">
            <div className="financial-impact__header">
                <Header size="medium">Financial impact</Header>
                <SelectedViewTab
                    selected={selectedView === "incremental"}
                    title="Incremental"
                    onSelect={() => setSelectedView("incremental")}
                />
                <SelectedViewTab
                    selected={selectedView === "total"}
                    title="Total"
                    onSelect={() => setSelectedView("total")}
                />
            </div>
            <ImpactCards
                carbonReductionMeasureType={carbonReductionMeasureType}
                selected={selectedView}
            />
            <div>
                <TotalCostAndSavingsCard
                    selectedView={selectedView}
                    carbonReductionMeasureType={carbonReductionMeasureType}
                />
                <CashFlowChartWrapper
                    carbonReductionMeasureType={carbonReductionMeasureType}
                    selected={selectedView}
                />
            </div>
        </div>
    );
}

interface SelectedViewTabProps {
    selected: boolean;
    title: string;
    onSelect: () => void;
}

function SelectedViewTab({ selected, title, onSelect }: SelectedViewTabProps) {
    return (
        <Button
            className={classNames("select-view-tab", selected && "selected")}
            onClick={onSelect}
        >
            {title}
        </Button>
    );
}

interface ImpactCardsProps extends FinancialImpactProps {
    selected: "total" | "incremental";
}

function ImpactCards({
    carbonReductionMeasureType,
    selected,
}: ImpactCardsProps) {
    const {
        data: impactCardsData,
        isLoading: impactCardsDataIsLoading,
        isError,
    } = useImpactCards(carbonReductionMeasureType);

    if (isError)
        return (
            <div className="small-cards">
                {impactCardsKeys.map((key) => (
                    <CardErrorState key={key} />
                ))}
            </div>
        );
    if (impactCardsDataIsLoading || !impactCardsData)
        return <ImpactCardLoading />;

    return (
        <div className="small-cards">
            {impactCardsKeys.map((key) => {
                const { humanReadable, formatFunction, unit } = getDisplayInfo(
                    key,
                    impactCardsData![selected][key]
                );

                return (
                    <div className="card card--small" key={key}>
                        <Paragraph>
                            {selected === "incremental"
                                ? getIncrementalImpactCardTitle(humanReadable)
                                : humanReadable}
                        </Paragraph>
                        <Header size="medium">
                            {displayValueWithUnit(
                                impactCardsData![selected][key],
                                unit,
                                (v) => formatFunction(v)
                            )}
                        </Header>
                    </div>
                );
            })}
        </div>
    );
}

const getIncrementalImpactCardTitle = (title: string) => {
    if (title.length > 3) return `Incremental ${title.toLowerCase()}`;
    return `Incremental ${title}`; // IRR, ROI
};

function ImpactCardLoading() {
    return (
        <div className="small-cards">
            {Array.from({ length: 5 }).map((_, i) => (
                <div
                    className="card small--card"
                    data-testid="card-loading"
                    // eslint-disable-next-line react/no-array-index-key
                    key={i}
                >
                    <div
                        className="loading"
                        style={{
                            height: "12px",
                            width: "30%",
                            borderRadius: "12px",
                        }}
                    />
                    <div
                        className="loading"
                        style={{
                            height: "12px",
                            width: "80%",
                            borderRadius: "12px",
                        }}
                    />
                </div>
            ))}
        </div>
    );
}

interface TotalCostAndSavingsCardProps {
    selectedView: "incremental" | "total";
    carbonReductionMeasureType: string;
}

function TotalCostAndSavingsCard({
    selectedView,
    carbonReductionMeasureType,
}: TotalCostAndSavingsCardProps) {
    const {
        data: cardData,
        isLoading,
        isError,
    } = useTotalCostAndSavingsCard(carbonReductionMeasureType);

    const data = useMemo(() => {
        if (!cardData) return null;
        return buildTotalCostAndSavingsCardDataStruct(cardData[selectedView]);
    }, [selectedView, cardData]);

    const {
        humanReadable: discountRateTitle,
        unit: discountRateUnit,
        formatFunction: discountFormatFn,
    } = getDisplayInfo("discountRate");

    if (isError) return <CardErrorState />;
    if (isLoading || !data) return <LargeCardLoading />;

    return (
        <div className="large-card">
            <Header size="small">Costs & Savings</Header>
            {data.map((section) => (
                <ProjectCardSection key={section.title.key} data={section} />
            ))}
            <div className="discount-rate">
                <Paragraph size="small">{discountRateTitle}</Paragraph>
                <Paragraph size="small">
                    {displayValueWithUnit(
                        cardData![selectedView].discountRate,
                        discountRateUnit,
                        (v) => discountFormatFn(Number(v) * 100)
                    )}
                </Paragraph>
            </div>
        </div>
    );
}

function LargeCardLoading() {
    const widths = [86, 150, 50, 172, 50, 150, 50, 150, 50];

    return (
        <div
            className="large-card large-card--loading"
            data-testid="card-loading"
        >
            {widths.map((width, index) => (
                <div
                    className="large-card--loading__grid-item"
                    // eslint-disable-next-line react/no-array-index-key
                    key={`${width}$${index}`}
                >
                    <div
                        className="loading"
                        style={{
                            height: "12px",
                            width: `${width}px`,
                            borderRadius: "12px",
                        }}
                    />
                </div>
            ))}
        </div>
    );
}

interface CardSection<T extends keyof any> {
    title: {
        key: T;
        icon: React.ReactNode;
        value: number;
    };
    body: {
        key: T;
        value: number;
        percentage?: number;
    }[];
}

interface ProjectCardSectionProps {
    data: CardSection<string>;
}

function ProjectCardSection({ data }: ProjectCardSectionProps) {
    const {
        humanReadable: title,
        formatFunction: formatTitle,
        unit,
    } = getDisplayInfo(data.title.key, data.title.value);

    return (
        <div className="project-card-section">
            <div className="project-card-section__title">
                {data.title.icon}
                <Header size="x-small">{title}</Header>
                <Header
                    size="x-small"
                    style={{
                        color: getTextColorByValue(
                            data.title.value,
                            title.includes("cost")
                        ),
                    }}
                >
                    {displayValueWithUnit(data.title.value, unit, (v) =>
                        formatTitle(v)
                    )}
                </Header>
            </div>
            <ul className="project-card-section__body">
                {data.body.map(({ key, value, percentage }) => {
                    const { humanReadable, formatFunction, unit } =
                        getDisplayInfo(key, value);

                    const displayValue = humanReadable
                        .toLowerCase()
                        .replace("cost", "")
                        .trim();

                    return (
                        <li key={key}>
                            <div>
                                <Paragraph size="small">
                                    {capitalizeFirstLetter(displayValue)}
                                </Paragraph>
                                <Paragraph size="small">
                                    {displayValueWithUnit(value, unit, (v) =>
                                        formatFunction(v)
                                    )}
                                </Paragraph>
                                {percentage !== undefined && (
                                    <Paragraph size="small">
                                        {formatPercentage(percentage)}%
                                    </Paragraph>
                                )}
                            </div>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
}



interface PerformanceImpactProps {
    carbonReductionMeasureType: string;
    carbonReductionMeasureCategory: string;
}

function PerformanceImpact({
    carbonReductionMeasureType,
    carbonReductionMeasureCategory,
}: PerformanceImpactProps) {
    return (
        <div className="project-report__section">
            <Header size="medium">Performance impact</Header>
            <div className="performance-impact">
                <TotalCarbonEmissionsAndEnergyCard
                    carbonReductionMeasureType={carbonReductionMeasureType}
                />
                <DecarbonizationForecastChart
                    carbonReductionMeasureCategory={
                        carbonReductionMeasureCategory
                    }
                    carbonReductionMeasureType={carbonReductionMeasureType}
                />
            </div>
        </div>
    );
}

interface TotalCarbonEmissionsCardProps {
    carbonReductionMeasureType: string;
}

function TotalCarbonEmissionsAndEnergyCard({
    carbonReductionMeasureType,
}: TotalCarbonEmissionsCardProps) {
    const {
        data: carbonEmissionsData,
        isLoading: carbonEmissionsLoading,
        isError: carbonEmissionsError,
    } = useTotalCarbonEmissionsCard(carbonReductionMeasureType);

    const {
        data: energyData,
        isLoading: energyLoading,
        isError: energyError,
    } = useTotalEnergyConsumptionCard(carbonReductionMeasureType);

    const cardData = useMemo(() => {
        if (!carbonEmissionsData || !energyData) return undefined;
        return buildTotalCarbonEmissionSavingsCardStruct(
            carbonEmissionsData,
            energyData
        );
    }, [carbonEmissionsData, energyData]);

    if (carbonEmissionsError || energyError) return <CardErrorState />;
    if (energyLoading || carbonEmissionsLoading || !cardData)
        return <LargeCardLoading />;

    return (
        <div className="large-card">
            <Header size="small">Energy & Emissions savings</Header>
            {cardData.map((section) => (
                <ProjectCardSection key={section.title.key} data={section} />
            ))}
        </div>
    );
}

const buildTotalCarbonEmissionSavingsCardStruct = (
    carbonEmissionsData: MeasureReportTotalCarbonEmissionsCard,
    energyData: MeasureReportTotalEnergyConsumptionCard
): CardSection<string>[] => [
    {
        title: {
            key: "totalEnergyConsumptionSavings",
            icon: <LeafIcon />,
            value: energyData.totalEnergyConsumptionSavings,
        },
        body: [
            {
                key: "totalEnergyConsumptionSavingsElectricity",
                value: energyData.totalEnergyConsumptionSavingsElectricity,
            },
            {
                key: "totalEnergyConsumptionSavingsNaturalGas",
                value: energyData.totalEnergyConsumptionSavingsNaturalGas,
            },
        ],
    },
    {
        title: {
            key: "totalCarbonEmissionSavings",
            icon: <LeafIcon />,
            value: carbonEmissionsData.totalCarbonEmissionSavings,
        },
        body: [
            {
                key: "totalCarbonEmissionSavingsElectricity",
                value: carbonEmissionsData.totalCarbonEmissionSavingsElectricity,
            },
            {
                key: "totalCarbonEmissionSavingsNaturalGas",
                value: carbonEmissionsData.totalCarbonEmissionSavingsNaturalGas,
            },
        ],
    },
];

const buildTotalCostAndSavingsCardDataStruct = (
    data:
        | ProjectReportTotalCostAndSavingsCardDatum
        | ProjectReportTotalCostAndSavingsCardTotal
): CardSection<string>[] => {
    const struct: CardSection<string>[] = [
        {
            title: {
                key: "totalEnergyCostSavings",
                icon: <LeafIcon />,
                value: data.totalEnergyCostSavings,
            },
            body: [
                {
                    key: "totalUtilityCostSavingsElectricity",
                    value: data.totalUtilityCostSavingsElectricity,
                },
                {
                    key: "totalUtilityCostSavingsNaturalGas",
                    value: data.totalUtilityCostSavingsNaturalGas,
                },
                {
                    key: "totalCarbonTaxSavings",
                    value: data.totalCarbonTaxSavings,
                },
            ],
        },
        {
            title: {
                key: "totalMeasureCost",
                icon: <HandHoldingMoneyIcon />,
                value: data.totalMeasureCost,
            },
            body: [
                {
                    key: "incrementalCost",
                    value: data.incrementalCost,
                },
            ],
        },
    ];

    if (data.likeForLikeCost !== undefined) {
        struct[1].body.unshift({
            key: "likeForLikeCost",
            value: data.likeForLikeCost,
        });
    }
    return struct;
};

interface DecarbonizationForecastChartProps {
    carbonReductionMeasureType: string;
    carbonReductionMeasureCategory: string;
}

function DecarbonizationForecastChart({
    carbonReductionMeasureType,
    carbonReductionMeasureCategory,
}: DecarbonizationForecastChartProps) {
    const margin = {
        top: 20,
        right: 26,
        bottom: 25,
        left: 60,
    };

    const {
        data: decarbonizationData,
        isLoading,
        isError,
    } = useMeasureReportDecarbonizationForecastChart(
        carbonReductionMeasureType
    );

    const {
        data: chartData,
        uniqueKeys,
        colors,
        maxValue,
    } = useMemo(() => {
        const chartKeyToColorMap = new Map();
        chartKeyToColorMap.set("Carbon emissions", "#E1E1E1");
        chartKeyToColorMap.set("Grid savings", "#E1E1E1");

        let maxValue = 0;

        if (!decarbonizationData)
            return {
                chartData: null,
                uniqueKeys: [],
                colors: [],
                maxValue: 0,
            };

        decarbonizationData?.annualData.forEach((datum) => {
            datum.carbonReductionMeasureTypes.forEach((measure) => {
                const {
                    carbonReductionMeasureType: measureType,
                    carbonReductionMeasureCategory: measureCategory,
                } = measure;

                let color =
                    carbonReductionMeasureCategory === measureCategory
                        ? "#AFAFAF"
                        : "#E1E1E1";

                if (measureType === carbonReductionMeasureType)
                    color = "#EB03AD";

                chartKeyToColorMap.set(measureType, color);
            });
        });

        const uniqueKeys = Array.from(chartKeyToColorMap.keys());

        const colors = Array.from(chartKeyToColorMap.values());

        const data = decarbonizationData?.annualData.map((measures) => {
            const defaultObj = Array.from(uniqueKeys).reduce(
                (prev, curr) => ({
                    ...prev,
                    [curr]: 0,
                }),
                {}
            );

            measures.carbonReductionMeasureTypes.forEach((measure) => {
                defaultObj[measure.carbonReductionMeasureType] =
                    measure.carbonEmissionSavings;

                if (measure.carbonEmissionSavings > maxValue)
                    maxValue = measure.carbonEmissionSavings;
            });

            return {
                ...defaultObj,
                "Carbon emissions": measures.remainingCarbonEmissions,
                "Grid savings": measures.carbonEmissionSavingsFromGrid,
                year: measures.calendarYear,
            };
        });

        return { data, chartKeyToColorMap, uniqueKeys, colors, maxValue };
    }, [decarbonizationData]);

    const { unit, formatFunction } = useMemo(
        () => getDisplayInfo("carbonEmissions", maxValue),
        [chartData, maxValue]
    );

    if (isError) return <ChartErrorState />;

    return (
        <ChartContainer
            title="Decarbonization forecast"
            loading={isLoading || !chartData || !decarbonizationData}
        >
            {decarbonizationData?.percentages && (
                <DecarbonizationForecastChartLegend
                    percentages={decarbonizationData.percentages}
                    carbonReductionMeasureCategory={
                        carbonReductionMeasureCategory
                    }
                />
            )}
            <div style={{ height: "253px", width: "100%" }}>
                <ResponsiveStream
                    data={chartData!}
                    fill={[{ match: "*", id: "gradient" }]}
                    theme={theme}
                    keys={uniqueKeys}
                    valueFormat={(v) => formatFunction(v, false)}
                    axisTop={null}
                    axisRight={null}
                    axisBottom={{
                        tickSize: 3,
                        tickPadding: 5,
                        legendPosition: "middle",
                        legendOffset: 36,
                        format: (v) => {
                            if (v === 0) return CURRENT_YEAR;
                            if (v === (chartData!.length || 0) - 1)
                                return "2050";
                            return "";
                        },
                    }}
                    tooltip={() => null}
                    axisLeft={{
                        tickSize: 3,
                        tickPadding: 5,
                        tickRotation: 0,
                        legendPosition: "middle",
                        legend: unit,
                        legendOffset: margin ? margin.left * -1 + 5 : -50,
                        format: (v) => formatFunction(v, false),
                    }}
                    borderWidth={1}
                    borderColor="#fff"
                    colors={colors}
                    offsetType="none"
                    margin={margin}
                    enableStackTooltip={false}
                />
            </div>
        </ChartContainer>
    );
}

interface DecarbonizationForecastChartLegendProps {
    carbonReductionMeasureCategory: string;
    percentages: CarbonReductionMeasureDecarbonizationPercentages;
}

function DecarbonizationForecastChartLegend({
    carbonReductionMeasureCategory,
    percentages,
}: DecarbonizationForecastChartLegendProps) {
    const { currentMeasure, sameMeasureTypes, otherMeasureTypes } = percentages;

    return (
        <div className="decarbonization-forecast-chart-legend">
            <div className="decarbonization-forecast-chart-legend__item">
                <div
                    className="decarbonization-forecast-chart-legend__item__color"
                    style={{ backgroundColor: "#EB03AD" }}
                />
                <Paragraph size="small">
                    This project:{" "}
                    <InlineBoldParagraph>{`${formatPercentage(
                        currentMeasure * 100
                    )}%`}</InlineBoldParagraph>
                </Paragraph>
            </div>
            <div className="decarbonization-forecast-chart-legend__item">
                <div
                    className="decarbonization-forecast-chart-legend__item__color"
                    style={{ backgroundColor: "#AFAFAF" }}
                />
                <Paragraph size="small">
                    {`Other ${carbonReductionMeasureCategory} projects:`}{" "}
                    <InlineBoldParagraph>{`${formatPercentage(
                        sameMeasureTypes * 100
                    )}%`}</InlineBoldParagraph>
                </Paragraph>
            </div>
            <div className="decarbonization-forecast-chart-legend__item">
                <div
                    className="decarbonization-forecast-chart-legend__item__color"
                    style={{ backgroundColor: "#E1E1E1" }}
                />
                <Paragraph size="small">
                    Other projects:{" "}
                    <InlineBoldParagraph>{`${formatPercentage(
                        otherMeasureTypes * 100
                    )}%`}</InlineBoldParagraph>
                </Paragraph>
            </div>
        </div>
    );
}





const impactCardsKeys: (keyof ProjectReportImpactCardsData)[] = [
    "netPresentValue",
    "internalRateOfReturn",
    "returnOnInvestment",
    "simplePayback",
    "marginalAbatementCost",
];

const measureSizeUnitToHumanReadableSingular: {
    [unit: string]: string;
} = {
    cfm_of_ahu_supply_air_rate: "cfm",
    heating_load_cop: "seasonal COP",
    kw: "kW",
    kwh: "kWh",
    m2: "m²",
    m2_of_gfa: "m² of GFA",
    m2_of_roof_area: "m² of roof",
    m2_of_window_area: "m² of window",
    seal: "seal",
    ton: "ton",
};

const measureSizeUnitSingularPluralMap: {
    [unit: string]: string;
} = {
    dryer: "dryers",
    elevator: "elevators",
    escalator: "escalators",
    seal: "seals",
    suite: "suites",
    ton: "tonnes",
    zone: "zones",
};

const measureNameToDescriptionMap: { [type: string]: string } = {
    "Increase Roof Insulation to R50":
        "Upgrade existing roof insulation to R50 during the next replacement at the end of its useful life.",
    "Increase Roof Insulation to R60":
        "Upgrade existing roof insulation to R60 during the next replacement at the end of its useful life.",
    "Install Advanced Glazing (~R6)":
        "Replace existing glazing with high performance glazing (R6/U-0.167) and climate-appropriate solar heat gain at the end of its useful life.",
    "Install Advanced Glazing (~R8)":
        "Replace existing glazing with high-performance glazing (R8/U-0.125) and climate-appropriate solar heat gain at the end of its useful life.",
    "Install Pressure-Applied Aerosolized Sealant":
        "Improve building energy efficiency by implementing a phased application of pressure-applied atomized sealant to reduce air infiltration during periods of low occupancy, such as weekends, vacancies, or tenant turnover.",
    "Install Air-Source Heat Pump Sized to Replace the Chiller and Supplement/Replace Boiler":
        "Upgrade your building's HVAC system with an Air Source Heat Pump (ASHP), offering efficient heating and cooling while replacing the chiller and potentially supplementing or replacing the boiler. With a calculated ASHP load ratio of 1.0 and a seasonal heating coefficient of performance (COP) of 3.72, this measure ensures energy-efficient operation across various climate zones.",
    "Replace existing dryer(s) with dryer air-source heat pump(s)":
        "Replace existing clothes dryer(s) with heat pump clothes dryer(s).",
    "Replace Existing Boiler with a Condensing Boiler":
        "Replace the existing standard efficiency boiler(s) with modulating condensing boiler(s) at the end of their useful life.",
    "Install DCV Controls":
        "Install Demand-Controlled Ventilation (DCV) controls in all applicable zones while maintaining adequate ventilation rates (assumes 25% ventilation rate reduction for 0.65 of the gross building area).",
    "Install Domestic Hot Water Ambient Air-Source Heat Pump":
        "Replace existing domestic hot water heater(s) with an ambient air heat pump water heater(s).",
    "Install a Micro Combined Heat and Power System for Domestic Hot Water":
        "Enhance your building's energy efficiency and reduce carbon emissions by installing a micro combined heat and power (CHP) system designed to replace the domestic hot water (DHW) load.",
    "Install Drain Water Heat Recovery (Gravity Film Exchanger)":
        "Implement drain water heat recovery (DWHR) systems, specifically gravity film exchanger plumbing stacks, to recover heat from wastewater and reduce energy consumption.",
    "Install VFDs on Escalator Drives":
        "Install regenerative drives on passenger escalator(s).",
    "Install Furnace (Ducted) Air-Source Heat Pump(s) [Aux. Elec. Resistance]":
        "Upgrade your HVAC system with a Furnace (Ducted) Air-Source Heat Pump (ASHP) to reduce carbon emissions. Sized to handle the full cooling load and up to 37.5% of the heating load variability, this measure ensures efficient operation across seasons.",
    "Install Furnace (Ducted) Air-Source Heat Pump(s) [Aux. Gas Burner]":
        "Upgrade your HVAC system with a Furnace (Ducted) Air-Source Heat Pump (ASHP), incorporating an auxiliary gas burner for efficient heating and cooling. Sized at the full cooling load plus or minus 37.5% of the heating load, the ASHP ensures optimal performance in various climate conditions.",
    "Install Ground-Source Heat Pump Size to Replace the Chiller and Supplement/Replace Boiler":
        "Upgrade your outdated gas furnace with a high-efficiency model boasting a COP of 0.96, delivering optimal heating performance while minimizing energy consumption.",
    "Include Energy Recovery During AHU(s) Replacement":
        "Include energy recovery thermal wheel in AHU(s) at replacement.",
    "Install Heat Recovery Water-Source Heat Pump":
        "Upgrade your heating system with a Heat Recovery Water-Source Heat Pump to efficiently utilize wasted heat, reduce energy costs, and lower carbon emissions.",
    "Install Heat-Only Air-Source Heat Pump":
        "This measure involves installing a heat-only air-source heat pump (ASHP) to meet the building's heating needs down to -10°C, with a backup boiler to cover heating requirements below that temperature.",
    "Install Packaged Terminal Heat Pump(s) [Aux. Electric Resistance]":
        "Replace existing Packaged Terminal Air Conditioner(s) [PTAC(s)] with Packaged Terminal Heat Pump(s) [with Aux. Electric Resistance] sized to the cooling load",
    "Replace Existing Gas Furnace with a High-Efficiency Gas Furnace":
        "Upgrade your outdated gas furnace with a high-efficiency model boasting a COP of 0.96, delivering optimal heating performance while minimizing energy consumption.",
    "Replace Existing Steam Heat Exchanger with a High-Efficiency Heat Exchanger":
        "Enhance your building's efficiency by replacing your current steam heat exchanger with a high-efficiency model, reducing energy consumption and carbon emissions.",
    "Install LED Lighting":
        "Replace existing lighting with LED lighting throughout of the property ensuring adequate lighting levels are maintained.",
    "Replace Chiller with Oil-Free, Magnetic-Bearing Compressor Chiller":
        "Replace the existing chiller(s) with magnetic bearing, oil-free compressor chiller(s) at the end of their useful life.",
    "Install Regen Drives on Passenger Elevator(s)":
        "Install regenerative drives on passenger elevator(s).",
    "Install a No-Export Rooftop PV System":
        "Install a no-export, ballasted rooftop PV system with a shallow tilt, sized to the smaller of available roof space and summer mid-day electric demand average.",
    "Install Hybrid RTU Air-Source Heat Pump(s) [Aux. Elec. Resistance]":
        "Replace existing RTU(s) with hybrid, inverter-compressor air-source heat pump RTU(s) sized up to 1.25 of the cooling load with a electric resistance backup.",
    "Install Hybrid RTU Air-Source Heat Pump(s) [Aux. Gas Burner]":
        "Replace existing RTU(s) with hybrid, inverter-compressor air-source heat pump RTU(s) sized up to 1.25 of the cooling load with a gas backup.",
    "Install Run-Around Heat Recovery Loop on Ventilation System":
        "Integrate run-around heat recovery loops into the ventilation system to recover heat from the exhaust air and preheat the incoming fresh air.",
    "Install Split (Ductless) Air-Source Heat Pump(s) to Supplement Existing Baseboard Heating":
        "Install split (ductless) air-source heat pump(s) to supplement existing baseboard heating.",
    "Install Heat Recovery Ventilator(s)":
        "Implement heat recovery ventilators (HRVs) to enhance indoor air quality and energy efficiency by exchanging heat between outgoing and incoming air streams.",
    "Replace Existing Low-Voltage Dry Transformers with Ultra-Low Loss Transformers":
        "Replace existing low-voltage dry transformers with ultra-low loss transformers.",
    "Install Window Air-Source Heat Pump Sized to Supplement the Primary Heating System":
        "Upgrade your home's cooling system with a window air-source heat pump, providing efficient cooling and supplemental heating for improved comfort and energy savings.",
    "Install Low-E Window Film with Climate Appropriate SHGC":
        "Improve building energy efficiency by installing low-emissivity (low-e) window film tailored to the climate, effectively reducing solar heat gain while maintaining natural light levels, on existing glazing surfaces.",
    "Reduce Air Infiltration with Traditional Weatherization":
        "Enhance building energy efficiency by implementing traditional weatherization techniques, such as caulking, taping, and weatherstripping, to reduce air infiltration.",
};


export default ProjectsReport;
