import { OperatingCostCard } from "components/organisms/Charts";
import { useCurrentAnnualOperatingCostCard } from "queries/Reports/Baseline/useCurrentAnnualOperatingCostCard";

function TotalAnnualOperatingCostCard() {
    const { data, isLoading, error } = useCurrentAnnualOperatingCostCard();
    return (
        <OperatingCostCard
            data={data}
            loading={isLoading}
            error={error?.toString()}
        />
    );
}

export default TotalAnnualOperatingCostCard;