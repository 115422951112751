import { EndUseStackedBarChart } from "components/organisms/Charts";
import { useAnnualCarbonEmissionsElectricityChart } from "queries/Reports/Baseline/useAnnualCarbonEmissionsElectricityChart";

function AnnualCarbonEmissionsElectricityChart() {
    const { data, isLoading, error } =
        useAnnualCarbonEmissionsElectricityChart();

    return (
        <EndUseStackedBarChart
            data={data}
            loading={isLoading}
            fuelTypeKey="electricity"
            error={error?.toString()}
        />
    );
}

export default AnnualCarbonEmissionsElectricityChart;