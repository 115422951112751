import { useState, useEffect, useMemo } from "react";
import posthog from "posthog-js";

// components
import Sidebar from "components/molecules/Sidebar";
import FilterContainer from "components/molecules/FilterContainer/FilterContainer";
import Filter from "components/molecules/Filter/Filter";

// hooks
import useFilters, { emptyFilters } from "recoilStore/useFilters";
import { useCreateReportFilterset } from "mutations/Measures/useCreateReportFiltersetMutation";
import { useDebounce } from "hooks/useDebounce";

import {
    buildingsFilterKeyToInfoMap,
} from "./FilterSidebar.constants";

// helpers

import {
    removeFilter,
    buildIsFieldActiveMap,
    getFilterNameFromKey,
    cloneFilters,
} from "./FilterSidebar.helpers";

// styling
import "./index.scss";
import FiltersSidebarFooter from "./Filter/FiltersSidebarFooter";

interface FiltersSidebarProps {
    closeSidebar: () => void;
    sidebarOpen: boolean;
    page: Page;
    currentActiveTab?: ReportTab;
    setFiltersetId?: (filtersetId: string | null) => void;
}

function FiltersSidebar({
    page,
    closeSidebar,
    sidebarOpen,
    currentActiveTab,
    setFiltersetId,
}: FiltersSidebarProps) {
    const { setFilters, filters } = useFilters(page);
    const [localFilters, setLocalFilters] = useState(cloneFilters(filters));
    const [isFieldActiveMap, setIsFieldActiveMap] = useState(
        buildIsFieldActiveMap(filters)
    );
    const { mutate: mutateCreateReportFilterset } = useCreateReportFilterset();

    const debouncedSetLocalFilters = useDebounce(setLocalFilters, 300);
    
    const numberOfLocalFilters = useMemo(() => {
        const {
            numericFilters,
            stringFilters,
            dateFilters,
            dataCoverageFilter,
        } = localFilters;
        return (
            numericFilters.length +
            stringFilters.length +
            dateFilters.length +
            dataCoverageFilter.length
        );
    }, [localFilters]);

    useEffect(() => {
        setLocalFilters(cloneFilters(filters));
        setIsFieldActiveMap(buildIsFieldActiveMap(filters));
    }, [filters]);

    const handleApplyFilters = async () => {
        await Promise.resolve(setFilters(localFilters));
        const posthogSimplifiedFilters = {
            ...localFilters,
            numericFilters: localFilters.numericFilters.map(
                ({ field }) => field
            ),
            stringFilters: localFilters.stringFilters.map(({ field }) => field),
        };

        posthog.capture("Filters applied", posthogSimplifiedFilters);


        if (page === "reports" && currentActiveTab === "projects" && setFiltersetId) {
            mutateCreateReportFilterset(undefined, {
                // Got to specify undefined to avoid TS error
                onSuccess: (data) => {
                    setFiltersetId(data.createReportFilterset ?? null);
                },
            });        }

        closeSidebar();
        setIsFieldActiveMap(buildIsFieldActiveMap(localFilters));
    };

    const handleClearAll = () => {
        setLocalFilters(emptyFilters);
        posthog.capture("Filters cleared");
        setIsFieldActiveMap(buildIsFieldActiveMap(emptyFilters));
    };

    const handleCancel = () => {
        posthog.capture("Filters closed");
        setLocalFilters({ ...filters });
        closeSidebar();
        setIsFieldActiveMap(buildIsFieldActiveMap(filters));
    };

    const handleClearFilter = (filterField: string) =>
        setLocalFilters((prev) => removeFilter(prev, filterField));

    const handleSelectFilter = (checked: boolean, key: string) => {
        setIsFieldActiveMap((prev) => {
            const copy = new Map(prev);
            copy.set(key, checked);
            return copy;
        });
    };

    const filtersMap = useMemo(() => {
        if (page === "measures") return buildingsFilterKeyToInfoMap;
        return buildingsFilterKeyToInfoMap;
    }, [page]);

    return (
        <Sidebar
            onCancel={handleCancel}
            sidebarOpen={sidebarOpen}
            title={`Filters ${
                numberOfLocalFilters > 0 ? `(${numberOfLocalFilters})` : ""
            }`}
        >
            <div className="filters-sidebar__body">
                {Object.keys(filtersMap).map((key) => {
                    const { type, field } = buildingsFilterKeyToInfoMap[key];
                    if (
                        key === "createdAt" &&
                        (page === "reports" || page === "measures")
                    )
                        return null;
                    return (
                        <FilterContainer
                            name={getFilterNameFromKey(key)}
                            checked={!!isFieldActiveMap.get(field)}
                            setChecked={(checked) =>
                                handleSelectFilter(checked, field)
                            }
                            key={field}
                            handleUncheck={() => handleClearFilter(field)}
                        >
                            <Filter
                                filters={localFilters}
                                setFilters={debouncedSetLocalFilters}
                                filterKey={key}
                                filterField={field}
                                type={type}
                            />
                        </FilterContainer>
                    );
                })}
            </div>
            <FiltersSidebarFooter
                onClickApply={handleApplyFilters}
                onClickCancel={handleCancel}
                onClickClearAll={handleClearAll}
                page={page}
                localFilters={localFilters}
            />
        </Sidebar>
    );
}

export default FiltersSidebar;
