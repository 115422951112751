import "./BaselineReport.css";
import { Header } from "components/atoms/Typography";
import {
    ByBuildingBarChart,
    EmissionIntensityMap,
    AnnualCumulativePicker,
} from "components/organisms/Charts";
import { RadioButtonSelectMode } from "components/organisms/Charts/AnnualCumulativePicker";
import { useState } from "react";
import { CURRENT_YEAR } from "utils/constants";
import CurrentAnnualCarbonEmissionsCard from "./ReportCards/CurrentAnnualCarbonEmissionsCard";
import CumulativeCarbonEmissionsCard from "./ReportCards/CumulativeCarbonEmissionsCard";
import CumulativeEnergyConsumptionCard from "./ReportCards/CumulativeEnergyConsumptionCard";
import CumulativeOperatingCostCard from "./ReportCards/CumulativeOperatingCostCard";
import AnnualCarbonEmissionsCard from "./ReportCards/AnnualCarbonEmissionsCard";
import AnnualEnergyConsumptionCard from "./ReportCards/AnnualEnergyConsumptionCard";
import AnnualOperatingCostCard from "./ReportCards/AnnualOperatingCostCard";
import CurrentAnnualEnergyConsumptionCard from "./ReportCards/CurrentAnnualEnergyConsumptionCard";
import TotalAnnualOperatingCostCard from "./ReportCards/TotalAnnualOperatingCostCard";
import CurrentAnnualEndUseChart from "./ReportCards/CurrentAnnualEndUseChart";
import AnnualCarbonEmissionsChart from "./ReportCards/AnnualCarbonEmissionsChart";
import AnnualCarbonEmissionsNaturalGasChart from "./ReportCards/AnnualCarbonEmissionsNaturalGasChart";
import AnnualCarbonEmissionsElectricityChart from "./ReportCards/AnnualCarbonEmissionsElectricityChart";

function BaselineReport() {
    return (
        <div className="baseline-report">
            <Header size="medium">
                Your buildings at a glance - {CURRENT_YEAR}
            </Header>
            <div className="cards-and-chart-grid">
                <div className="cards">
                    <CurrentAnnualCarbonEmissionsCard />
                    <CurrentAnnualEnergyConsumptionCard />
                    <TotalAnnualOperatingCostCard />
                </div>
                <ByBuildingBarChart />
            </div>
            <div className="side-by-side-charts-grid">
                <CurrentAnnualEndUseChart />
                <EmissionIntensityMap />
            </div>
            <Header size="medium">Future projection</Header>
            <div className="cards-and-chart-grid">
                <FutureProjectionCards />
                <AnnualCarbonEmissionsChart />
            </div>
            <div className="side-by-side-charts-grid">
                <AnnualCarbonEmissionsNaturalGasChart />
                <AnnualCarbonEmissionsElectricityChart />
            </div>
        </div>
    );
}

function FutureProjectionCards() {
    const [selected, setSelected] =
        useState<RadioButtonSelectMode>("cumulative");
    const [sliderCurrentYear, setSliderCurrentYear] = useState(CURRENT_YEAR);

    const onChangeSelect = () => {
        setSelected((prev) => {
            if (prev === "cumulative") return "annual";
            return "cumulative";
        });
    };

    return (
        <div>
            <AnnualCumulativePicker
                selected={selected}
                onChangeSelect={onChangeSelect}
                currentSliderYear={sliderCurrentYear}
                setCurrentSliderYear={setSliderCurrentYear}
            />
            <div className="cards">
                {selected === "cumulative" ? (
                    <>
                        <CumulativeCarbonEmissionsCard />
                        <CumulativeEnergyConsumptionCard />
                        <CumulativeOperatingCostCard />
                    </>
                ) : (
                    <>
                        <AnnualCarbonEmissionsCard
                            selectedYear={sliderCurrentYear}
                        />
                        <AnnualEnergyConsumptionCard
                            selectedYear={sliderCurrentYear}
                        />
                        <AnnualOperatingCostCard
                            selectedYear={sliderCurrentYear}
                        />
                    </>
                )}
            </div>
        </div>
    );
}

export default BaselineReport;
